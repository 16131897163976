/*
 * @Author: taol taol@cnwenzheng.com
 * @Date: 2022-07-21 10:37:09
 * @LastEditors: taol taol@cnwenzheng.com
 * @LastEditTime: 2022-07-21 17:59:46
 * @FilePath: \xiao_wen_pc_g\packages\xwpc\src\config\router.config.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */



export const constantRouterMap = [
  
  {
    path:'/dingBack',
    component:() => import('@/views/Login/DingBack.vue')
  
  },
  {
    path:'/dingLogin',
component:() => import('@/views/Login/DingLogin.vue')
  },
  {
    path:'/dingBackOut',
    component:() => import('@/views/Login/DingBackOut.vue')
  
  },
  {
    path:'/dingLoginOut',
component:() => import('@/views/Login/DingLoginOut.vue')
  },
  {
    path: "/forgetPass",
    component:() => import('@/views/Login/ForgetPass.vue')
  },
  {
    path: "/resetPass",
    component:() => import('@/views/Login/ResetPass.vue')
  },
 
]
