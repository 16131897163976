<template>
  <div id="app">
       <router-view class="mainrouter" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    
  },
})
export default class App extends Vue {}
</script>

<style lang="less" >

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
}
.mainrouter{

//overflow-x: scroll;
}

body{
  margin:0px;
  padding:0px;
  overflow: hidden;
}
.el-table th.el-table__cell{
  background-color: #FAFAFA !important;
}
.el-input-group__append,.el-input-group__append{
  background-color: #fff !important;
}
.el-tabs__header{
  background: #fcfcfc;
}

.el-tabs__content{
  //不要随便修改.
  padding: 0px !important;
}
.el-dialog__wrapper{
 /* background: #ffffff !important;*/
}
.v-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .5;
  background: #ffffff !important;
}
</style>
