/*
 * @Author: taol taol@cnwenzheng.com
 * @Date: 2022-08-30 12:11:07
 * @LastEditors: taol taol@cnwenzheng.com
 * @LastEditTime: 2022-09-02 11:40:06
 * @FilePath: \frontskill\src\main.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import router from './router'
Vue.config.productionTip = false

import 'element-ui/lib/theme-chalk/index.css';




Vue.use(Router);
new Vue({
  
  render: h => h(App),
  router,
}).$mount('#app')
